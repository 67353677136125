import { ContentType } from '@/models/content'

/**
 * Generates the page path for a content depending on its type.
 *
 * @param contentType
 * @param slug
 */
export function useContentHref(
  contentType: ContentType,
  slug: string | number,
) {
  let hrefPrefix = ''

  if (contentType === 'video') {
    hrefPrefix = '/video/watch'
  }
  if (contentType === 'article') {
    hrefPrefix = '/article'
  }

  return `${hrefPrefix}/${slug}`
}
