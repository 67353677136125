import { ComputedRef } from 'vue'
import { formatVideoDuration, formatArticleDuration } from '@/utils/time'
import { AnyContent, Category, ContentType, Tag } from '@/models/content'

/**
 * Formats the duration of a content (e.g. '8 min read', '8:43'),
 * depending on the type of content.
 *
 * @param {string} contentType A string indicating the type content
 *    you want to parse the duration for.
 *
 * @param {number} duration A postitive integer indicating the
 *    duration (in seconds if contentType is "video", and minutes, in
 *    the case of "article") of the content.
 *
 * @returns {string} A computed human-readable string with the .
 */
export function useFormattedDuration(
  contentType: ContentType,
  duration: number,
): ComputedRef<string> {
  return computed(() =>
    contentType === 'video'
      ? formatVideoDuration(duration)
      : formatArticleDuration(duration),
  )
}

export function useFormattedPillsData(
  video: AnyContent,
  limit: number = 2,
): Category[] | Tag[] {
  const arrayCategories = video.categories
  const arrayTags = video.tags
  const formattedArray = []
  if (arrayCategories.length > 0) formattedArray.push(arrayCategories[0])
  if (arrayTags.length > 0) formattedArray.push(...arrayTags)
  return formattedArray.slice(0, limit)
}
