import { intervalToDuration } from 'date-fns'
import { zeroPad } from '@/utils/number'

export function formatVideoDuration(_seconds: number): string {
  const duration = intervalToDuration({ start: 0, end: _seconds * 1000 })

  const { hours, minutes, seconds } = duration

  if (hours) {
    return `${hours}:${zeroPad(minutes)}:${zeroPad(seconds)}`
  }

  if (minutes) {
    return `${minutes}:${zeroPad(seconds)}`
  }

  return `0:${zeroPad(seconds)}`
}

export function formatArticleDuration(minutes: number): string {
  return `${minutes} min read`
}
